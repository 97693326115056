.chats-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    height: 80px;
    border-bottom: 1px solid var(--current-line);
    display: flex;
    align-items: center;
    padding: 20px;

    text-decoration: none;
    color: var(--foreground);

    cursor: pointer;

    &--active {
      background-color: var(--current-line);
    }
  }
}
