.main {
  display: flex;
  height: 100vh;

  &__aside {
    width: 300px;
    border-right: 1px solid var(--current-line);
  }

  &__chat {
    flex: auto;
  }
}
