.call-widget {
  border-radius: 8px;
  border: 1px solid var(--comment);

  position: absolute;
  right: 20px;

  &__videos {
    display: flex;
  }
}

.call-controlls {
}

.video-container {
  height: 150px;
  width: 150px;
  background-color: antiquewhite;
  margin-right: 10px;
  border-right: 1px solid black;

  &:last-child {
    margin-right: 0;
    border-right: none;
  }
}

.call-request {
  width: 240px;
  height: 200px;
  border-radius: 4px;

  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  background-color: var(--current-line);

  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    width: 100px;
    height: 40px;
    border-radius: 4px;
    border: none;

    color: white;

    cursor: pointer;

    &.green {
      background-color: rgb(98, 227, 98);
    }

    &.red {
      background-color: rgb(240, 84, 84);
    }
  }
}
