.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login {
  display: flex;
  flex-direction: column;
}
