@import url("./normalize/normailize.scss");

:root {
  --background: #282a36;
  --current-line: #44475a;
  --foreground: #f8f8f2;
  --comment: #6272a4;
  --purple: #bd93f9;
}

body {
  background-color: var(--background);
  color: var(--foreground);
}
