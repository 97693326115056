.user-search {
  padding: 20px;
  display: flex;
  border-bottom: 2px solid var(--current-line);

  &__input {
    height: 40px;
    border: 1px solid var(--current-line);
    background-color: var(--background-color);
    color: var(--foreground);

    padding: 10px 5px;

    flex: auto;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    outline: none;

    &::placeholder {
      color: var(--current-line);
    }
  }

  &__btn {
    height: 40px;
    width: 60px;
    background-color: var(--background-color);

    color: var(--foreground);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;

    padding: 0;
    outline: none;
    border: 1px solid var(--current-line);
    border-left: none;

    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    cursor: pointer;
    transition: opacity ease-in-out 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
