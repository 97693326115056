.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 600px;
  padding: 30px 30px 20px;
  position: relative;
}

.chat {
  height: 100%;
  margin-bottom: 10px;
  padding: 10px;
  overflow: auto;

  height: 600px;
  flex-direction: column-reverse;
  display: flex;
}

.message {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &--owner {
    align-items: flex-end;

    .message__wrapper {
      background-color: var(--purple);
    }
  }

  &__wrapper {
    background-color: var(--comment);
    display: inline-flex;
    flex-direction: column;
    max-width: 50%;
    border-radius: 10px;
  }

  &__text {
    padding: 10px;
  }

  &__image {
    width: 100%;
  }
}

.chat-input {
  position: relative;

  &__text-area {
    border: none;
    background-color: var(--current-line);
    color: var(--foreground);

    width: 100%;

    padding: 20px;
    border-radius: 20px;
    outline: none;
    resize: none;

    &::placeholder {
      color: var(--current-line);
    }
  }

  &__submit {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;

    border-radius: 50px;
    padding: 8px;

    color: var(--foreground);
    background-color: var(--comment);
    outline: none;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  &__submit-icon {
    height: 20px;
    width: 20px;
    fill: var(--foreground);
  }
}
